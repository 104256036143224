export function getStoredNotes() {
    var parser = new DOMParser();
    var notes = localStorage.getItem("notes");

    if (notes === null) {
        return parser.parseFromString("<notes></notes>", "text/xml");
    }
    return parser.parseFromString(notes, "text/xml");
}

export function setStoredNotes(newXML) {
    var serializer = new XMLSerializer();
    localStorage.setItem("notes", serializer.serializeToString(newXML.documentElement));
}
