import { findNodeCopy } from '../utilities/findNodeCopy.js';
import './NewNote.scss';

var nextId = 0;
function getNextId() {
    return ++nextId;
}


function inputKey(node) {
    return node.outerHTML + ".NewNode";
}


function submitNote(xmlParent, noteXML, setNoteXML) {
    var newXML = noteXML.cloneNode(true);
    var newParent = findNodeCopy(xmlParent, noteXML, newXML);

    var newNode = newXML.createElement("note");
    var input = document.getElementById(inputKey(xmlParent)).value;
    newNode.setAttribute("text", input);
    newNode.setAttribute("isOpen", "false");
    newNode.setAttribute("isComplete", "false");
    newNode.setAttribute("id", getNextId());

    newParent.appendChild(newNode);
    setNoteXML(newXML);
    return false;
}

function NewNote(props) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); return submitNote(props.xmlParent, props.noteXML, props.setNoteXML);}}>
            <input type="text"
                   placeholder=" New Reminder"
                   id={inputKey(props.xmlParent)}
            />
        </form>
    );
}

export default NewNote;
