import { useState } from 'react';
import Title from './Title';
import Notes from './Notes';
import Submission from './Submission';
import { getStoredNotes, setStoredNotes } from '../utilities/noteStorage.js';


function submitNotesAtNode(node) {
    for (var i = 0; i < node.childNodes.length; ++i) {
        if (node.childNodes[i].attributes.isComplete.nodeValue === "true") {
            node.removeChild(node.childNodes[i]);
        }
        else {
            submitNotesAtNode(node.childNodes[i]);
        }
    }
}

function submitNotes(noteXML, setNoteXML) {
    var newXML = noteXML.cloneNode(true);
    var newRoot = newXML.getElementsByTagName("notes")[0];
    submitNotesAtNode(newRoot);
    setNoteXML(newXML);
}



function updateNoteXML(setNoteXML, newXML) {
    setNoteXML(newXML);
    setStoredNotes(newXML);
}

function Reminders(props) {
    var [noteXML, setNoteXML] = useState(getStoredNotes());

    return (<>
        <Title />
        <Notes noteXML={noteXML}
               setNoteXML={(newXML) => updateNoteXML(setNoteXML, newXML)}
        />
        <Submission onSubmit={() => submitNotes(noteXML, (newXML) => updateNoteXML(setNoteXML, newXML))}/>
    </>);
}

export default Reminders;
