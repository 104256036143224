import './Title.scss';

function Title(props) {
    return (
        <div id="title">
            <h1>
                Reminders
            </h1>
        </div>
    );
}

export default Title;
