import './Submission.scss';
import { checkForSubmissionTutorialCompletion } from '../utilities/submissionTutorial.js';


function Submission(props) {
    var tutorialComplete = checkForSubmissionTutorialCompletion();

    return (
        <div id="submission-box" className={(tutorialComplete ? "sb-visible" : "sb-invisible")}>
            <button onClick={() => props.onSubmit()}>
                Submit
            </button>
        </div>
    );
}

export default Submission;
