import Note from '../components/Note';
import NewNote from '../components/NewNote';

export function getSubnotes(noteXML, setNoteXML, note) {
    var subnotes = []; 
    for (var i = 0; i < note.childNodes.length; ++i) {
        subnotes.push(
            <Note xmlNode={note.childNodes[i]}
                  noteXML={noteXML}
                  setNoteXML={(noteXML) => setNoteXML(noteXML)}
                  key={note.childNodes[i].outerHTML}
            />  
        );  
    }
    subnotes.push(
        <NewNote xmlParent={note}
                 noteXML={noteXML}
                 setNoteXML={(noteXML) => setNoteXML(noteXML)}
                 key={note.outerHTML + ".NewNode"}
        />
    );
    return subnotes;
}
