import arrow from '../assets/arrow.svg';
import { getSubnotes } from '../utilities/getSubnotes.js';
import { findNodeCopy } from '../utilities/findNodeCopy.js';
import { completeSubmissionTutorial } from '../utilities/submissionTutorial.js';
import './Note.scss';


function toggleAttribute(noteXML, setNoteXML, xmlNode, attribute) {
    var newXML = noteXML.cloneNode(true);
    var newNode = findNodeCopy(xmlNode, noteXML, newXML);

    if (newNode.attributes[attribute].nodeValue === "true") {
        newNode.attributes[attribute].nodeValue = "false";
    }
    else {
        newNode.attributes[attribute].nodeValue = "true";
    }

    setNoteXML(newXML);
}


function completeNote(noteXML, setNoteXML, xmlNode) {
    toggleAttribute(noteXML, setNoteXML, xmlNode, "isComplete");
    completeSubmissionTutorial();
}

function Note(props) {
    var isOpen = props.xmlNode.attributes.isOpen.nodeValue === "true";
    var isComplete = props.xmlNode.attributes.isComplete.nodeValue === "true";

    return (
        <div>
            <img src={arrow}
                 className={(isOpen ? "open-arrow" : "closed-arrow") + " arrow"}
                 alt={isOpen ? "Subnotes Open Indicator" : "Subnotes Closed Indicator"}
                 title={isOpen ? "Click To Close Subnotes" : "Click To Open Subnotes"}
                 onClick={() => toggleAttribute(props.noteXML, props.setNoteXML, props.xmlNode, "isOpen")}
            />
            <form>
                <input type="checkbox"
                       checked={isComplete}
                       id={props.xmlNode.outerHTML}
                       onChange={() => completeNote(props.noteXML, props.setNoteXML, props.xmlNode)}
                />
                <label htmlFor={props.xmlNode.outerHTML}>
                    <h2>
                        {props.xmlNode.attributes.text.nodeValue}
                    </h2>
                </label>
            </form>
            <div className={"indent" + (isOpen ? "" : " closed-subnotes")}>
                {getSubnotes(props.noteXML, props.setNoteXML, props.xmlNode)}
            </div>
        </div>
    );
}

export default Note;
