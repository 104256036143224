/* Returns the node copy if found, or undefined if not found */
function findNodeCopyFromNode(xmlNode, oldNode, newNode) {
    for (var i = 0; i < oldNode.childNodes.length; ++i) {
        if (xmlNode.id === oldNode.childNodes[i].id) {
            return newNode.childNodes[i];
        }

        var childCheck = findNodeCopyFromNode(xmlNode, oldNode.childNodes[i], newNode.childNodes[i]);
        if (childCheck !== undefined) {
            return childCheck;
        }
    }
    return undefined;
}


export function findNodeCopy(xmlNode, oldXML, newXML) {
    var oldRoot = oldXML.getElementsByTagName("notes")[0];
    var newRoot = newXML.getElementsByTagName("notes")[0];
    if (xmlNode.id === oldRoot.id) {
        return newRoot;
    }
    return findNodeCopyFromNode(xmlNode, oldRoot, newRoot);
}
